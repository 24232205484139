export default {
  colors: [
    "#CC0001",
    "#E36101",
    "#FFCC00",
    "#009900",
    "#0066CB",
    "#000000",
    "#FFFFFF"
  ],
  showBorder: true
};
